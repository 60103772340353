import React, { useEffect, useState } from "react";
import { BarChart, XAxis, YAxis, Tooltip, Bar, Legend } from "recharts";
import { decamelize } from "humps";

import Loader from "../../Templates/Loader";

const colors = [
  "#3d8b8b", // 80%: Teal, Orange, Purple
  "#f39151",
  "#705f7d",
  "#9ec5c5", // 40%: Teal, Orange, Purple
  "#f9c9a8",
  "#b9b0be",
  "#e27d6e", // 80% of supplementary palette: Red, Green, Violet, Maroon [left out yellow bc unreadable]
  "#74b99c",
  "#575e83",
  "#ba6773",
  "#cfe1e1", // 20%: Teal, Orange, Purple
  "#fae3d3",
  "#dbd8df",
];

const TrendingIllnessesPerClient = ({
  data,
  yAxisLabel,
  diseaseLabels,
  unit,
  isLoading,
}) => {
  const [bars, setBars] = useState([]);
  useEffect(() => {
    let stacks = [];
    // We had to use diseaseLabels instead of data because we need a unique list here
    // See recharts' StackedBarChart: http://recharts.org/en-US/examples/StackedBarChart
    if (!!diseaseLabels && diseaseLabels.length > 0) {
      diseaseLabels.map((label, index) => {
        if (label !== "site") {
          stacks.push(
            <Bar
              dataKey={decamelize(label, { separator: " " })}
              unit={unit}
              key={index}
              stackId="a"
              fill={
                colors[
                  index < colors.length
                    ? index
                    : Math.floor(index % colors.length) // This just loops through the colors again if it comes to the end
                ]
              }
              maxBarSize={40}
            />
          );
        }
      });
    }
    setBars(stacks);
  }, [data]);

  return (
    <center>
      {isLoading ? (
        <Loader />
      ) : (
        <BarChart width={800} height={300} data={data} margin={{ left: 20 }}>
          <XAxis dataKey="site" />
          <YAxis
            label={{
              value: yAxisLabel,
              angle: -90,
              position: "left",
            }}
            allowDecimals={unit.includes("%")} // We want ticks in whole numbers if our unit is absolute count
          />
          <Tooltip separator=" +" itemStyle={{ textTransform: "capitalize" }} />
          {bars}
          {/* <Legend
            wrapperStyle={{ textTransform: "capitalize" }}
            verticalAlign="bottom"
            height={60}
          /> */}
        </BarChart>
      )}
    </center>
  );
};

export default TrendingIllnessesPerClient;

import React from "react";
import Layout from "../../layout/Layout";
import SEO from "../../layout/Seo";
import AdminDashboard from "../../components/Admin/Dashboard/AdminDashboard";

export default () => (
  <Layout pageTitle="Admin Dashboard">
    <SEO title="Admin Dashboard" />
    <AdminDashboard />
  </Layout>
);

import React, { Fragment, useState, useEffect } from "react";
import classNames from "classnames";
import { decamelizeKeys } from "humps";
import moment from "moment";

import Dashboard from "../../Dashboard/Dashboard";
import Datalist from "../../Templates/Forms/Datalist";
import TrendingIllnessesPerClient from "./TrendingIllnessesPerClient";

import api from "../../../services/api";
import styles from "../../Dashboard/dashboard.module.scss";
import { getDiseaseLabels } from "../utils";

const initialClinics = [
  {
    label: "Select clinic...",
    value: "",
  },
];

const initialClients = [
  {
    label: "Select client...",
    value: "all",
  },
];

const initialDate = [
  {
    label: "Past 28 days",
    value: "",
  },
];

const constructMonthDropdown = () => {
  for (var i = 1; i <= 13; i++) {
    let currentMonth = moment().subtract(i, "months");
    let tempObject = {
      label: currentMonth.format("MMMM YYYY"),
      value: currentMonth.format("YYYY-MM"),
    };
    initialDate.push(tempObject);
  }

  return initialDate;
};

const AdminDashboard = () => {
  const [options, setOptions] = useState({
    clients: initialClients,
    clinics: initialClinics,
    date: initialDate,
  });
  const [selected, setSelected] = useState({
    client: options.clients[0],
    clinic: options.clinics[0],
    date: options.date[0],
  });
  const [fetchedData, setFetchedData] = useState([]);
  const [diseaseLabels, setDiseaseLabels] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [graphData, setGraphData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const date = constructMonthDropdown();
    setOptions({ ...options, date: date });
    setActiveTab("infectiousDiseases");
  }, []);

  useEffect(() => {
    if (options.clients.length < 2)
      api.get(`clients/?is_active=True`).then(response => {
        let tempClients = initialClients;
        tempClients = tempClients.concat(
          response.data.results.map(item => ({
            label: item.name,
            value: item.code,
          }))
        );
        setOptions({ ...options, clients: tempClients });
      });
  }, [options.clients]);

  useEffect(() => {
    if (selected.client !== "all")
      api
        .get(`clinics/?client__code=${selected.client.value}&is_active=True`)
        .then(response => {
          let tempClinics = response.data.results.map(item => ({
            label: item.name,
            value: item.id,
          }));
          setOptions({ ...options, clinics: tempClinics });
        });
  }, [selected]);

  // Fetch TrendingIllnessesPerClient data on client change
  useEffect(() => {
    setLoading(true);
    api
      .get(`reports/trending/?client=${selected.client.value}&date=today`)
      .then(response => {
        setFetchedData(response.data);
        setLoading(false);
      })
      .catch(error => console.error(error));
  }, [selected.client.value]);

  useEffect(() => {
    setGraphData(decamelizeKeys(fetchedData[activeTab], { separator: " " }));
    setDiseaseLabels(getDiseaseLabels(fetchedData[activeTab]));
  }, [fetchedData, activeTab]);

  const handleTabClick = event => {
    setActiveTab(event.currentTarget.id);
  };

  return (
    <Fragment>
      <div className="columns">
        <div className="column is-12-mobile is-4-tablet">
          <Datalist
            label="Client"
            hasLabel
            defaultValue={options.clients[0]}
            options={options.clients}
            value={selected.client}
            onChange={value => {
              setSelected({
                ...selected,
                client: value,
                clinic: initialClinics[0],
              });
            }}
          />
        </div>
      </div>

      {selected.client.value !== "all" && (
        <Fragment>
          <div className="tabs is-boxed">
            <ul>
              <li
                className={classNames({
                  "is-active": activeTab === "infectiousDiseases",
                })}
                id="infectiousDiseases"
                onClick={handleTabClick}
              >
                <span>Infectious Diseases</span>
              </li>
              <li
                className={classNames({
                  "is-active": activeTab === "topAssessments",
                })}
                id="topAssessments"
                onClick={handleTabClick}
              >
                <span>Top Assessments</span>
              </li>
            </ul>
          </div>
          <TrendingIllnessesPerClient
            data={graphData}
            yAxisLabel={
              activeTab === "topAssessments"
                ? "% Headcount Change"
                : "Count in Past 7 Days"
            }
            unit={activeTab === "topAssessments" ? "%" : ""}
            diseaseLabels={diseaseLabels}
            isLoading={isLoading}
          />
        </Fragment>
      )}
      <hr />
      <div className="columns">
        <div className="column">
          <Datalist
            label="Clinic"
            hasLabel
            defaultValue={options.clinics[0]}
            options={options.clinics}
            isDisabled={selected.client.value === "all"}
            value={selected.clinic}
            onChange={value => {
              setSelected({ ...selected, clinic: value });
            }}
          />
        </div>
        <div className="column">
          <Datalist
            label="Date"
            hasLabel
            defaultValue={options.date[0]}
            options={options.date}
            value={selected.date}
            onChange={value => {
              setSelected({ ...selected, date: value });
            }}
          />
        </div>
      </div>
      <div
        className={classNames(
          "mb-3",
          styles.dashboardCharts // This is just a min-height - so the container height doesn't change every chart switch bc it gets annoying
        )}
      >
        {selected.clinic.value === "" ? (
          <div className="content is-large has-text-centered">
            To view a specific dashboard, please choose a client or clinic
            first.
          </div>
        ) : (
          <Dashboard
            clinicId={selected.clinic.value}
            clientCode={selected.client.value}
            date={selected.date.value}
          />
        )}
      </div>
    </Fragment>
  );
};

export default AdminDashboard;
